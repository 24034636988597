import React from 'react';
import PropTypes from 'prop-types';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import Arrow from './Arrow';
import * as styles from './Slider.module.scss';

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #b4b0ae;
  border-radius: 50%;
`;

export const getCustomDots = (i) => {
  return <Dot />;
};

const Slider = ({ settings, children, className, ...props }) => {
  let defaultSetting = {
    prevArrow: <Arrow type="prev" />,
    nextArrow: <Arrow type="next" />,
    customPaging: (i) => getCustomDots(i),
    dots: true,
    dotsClass: styles.Dots,
  };

  if (settings !== undefined) {
    defaultSetting = { ...defaultSetting, ...settings };
  }

  return (
    <Slick
      ref={props.sliderRef}
      {...defaultSetting}
      className={className}
      {...props}
    >
      {children}
    </Slick>
  );
};

export default Slider;

Slider.propTypes = {
  settings: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Slider.defaultProps = {
  settings: {},
  className: '',
};
