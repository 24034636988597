import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowPrev from '../../assets/images/global/arrow-prev.png';
import ArrowNext from '../../assets/images/global/arrow-next.png';

const ArrowStyled = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
  width: 100%;
  height: 100%;
  max-width: 33px;
  max-height: 89px;
  cursor: pointer;

  ${(props) =>
    props.type === 'prev' &&
    `
    left: 2%;
    z-index: 150;    
    `}

  ${(props) =>
    props.type === 'next' &&
    `
    right: 2%;
    z-index: 150;
    `}
`;

const Arrow = ({ style, onClick, image, type, children }) => {
  let content =
    image === undefined ? (
      <img
        src={type === 'prev' ? ArrowPrev : ArrowNext}
        alt={`arrow ${type}`}
      />
    ) : (
      <img src={image} alt={`arrow ${type}`} />
    );

  if (children !== undefined) {
    content = children;
  }

  return (
    <ArrowStyled
      onClick={onClick}
      type={type}
      className={`arrow arrow-${type}`}
    >
      {content}
    </ArrowStyled>
  );
};

export default Arrow;

Arrow.propTypes = {
  type: PropTypes.oneOf(['prev', 'next']).isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func,
};
